<template>
  <v-card raised elevation="6" class="mt-1">
    <v-card-title>
      <h4
        style="
          font-size: 15px;
          text-transform: uppercase;
          font-weight: 100;
          text-decoration: underline;
        "
      >
        {{ $t("sales.byProducts") }}
      </h4>
      <v-spacer></v-spacer>
      <v-btn @click="graphic = !graphic" :outlined="graphic" height="30px"
        >Tabla</v-btn
      >
    </v-card-title>
    <v-card-text v-if="graphic"> <BarChartProducts /> </v-card-text>
    <v-data-table
      v-else
      :headers="headersByProducts"
      :items="list"
      :items-per-page="5"
      id="salesByProducts-table"
    >
      <template v-slot:item.product_id="{ item }">
        {{ item.product_id }}
      </template>
      <template v-slot:item.product_name="{ item }">
        {{ item.product_name }}
      </template>
      <template v-slot:item.cantidad="{ item }">
        {{ item.cantidad }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "SalesByProduct",
  components: {
    BarChartProducts: () =>
      import("@/components/stats/sales/graphics/SalesByProductGraphic"),
  },
  ...mapState("stats", ["lists"]),

  computed: {
    //METODO / VARIABLE
    list() {
      return this.$store.state.stats.lists.salesByProduct;
    },
  },
  data() {
    return {
      graphic: true,
      headersByProducts: [
        {
          text: this.$t("id"),
          align: "center",
          sortable: false,
          value: "product_id",
        },
        {
          text: this.$t("product.name"),
          align: "center",
          sortable: false,
          value: "inventory.product_name",
        },
        {
          text: this.$t("stats.sales"),
          align: "center",
          sortable: false,
          value: "cantidad",
        },
      ],
    };
  },
};
</script>

<style></style>
